[data-v-52ae009c] .el-table th.el-table__cell {
  background: #fafafa;
}
[data-v-52ae009c] .el-table--medium .el-table__cell {
  padding: 5px 0;
}
.right-toolbar[data-v-52ae009c] {
  float: right;
}
.search-form[data-v-52ae009c] {
  background: #FFFFFF;
  padding: 14px 12px;
}
.search-form[data-v-52ae009c] .el-form-item--small .el-form-item__content {
  line-height: 28px !important;
}
.search-form[data-v-52ae009c] .el-button {
  height: 28px;
  margin-left: 0;
}
.search-form[data-v-52ae009c] .el-input__inner {
  height: 28px;
  line-height: 28px;
}
[data-v-52ae009c] .search-form .el-form-item--small.el-form-item {
  margin-bottom: 0;
}
[data-v-52ae009c] .el-table {
  height: calc(100vh - 274px) !important;
}
[data-v-52ae009c] .el-table thead tr, .el-table thead tr th[data-v-52ae009c] {
  background: #fafafa !important;
}
[data-v-52ae009c] .el-table--border, .el-table--group[data-v-52ae009c] {
  border-color: #cccccc !important;
}
[data-v-52ae009c] .el-table__fixed-right::before {
  background-color: #ffffff !important;
}
[data-v-52ae009c] .el-table__fixed::before {
  background-color: #ffffff !important;
}
[data-v-52ae009c] .el-table--border::after, .el-table--group[data-v-52ae009c]::after, .el-table[data-v-52ae009c]::before {
  background-color: #cccccc !important;
}
[data-v-52ae009c] .el-table--border {
  border-bottom: 1px solid #cccccc;
}